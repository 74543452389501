<!-- ***** Header Area Start ***** -->
<header class="header_area clearfix sticky" id="top">
    <div class="container-fluid">
        <div class="row ">
            <!-- Menu Area Start -->
            <div class="col-12">
                <div class="menu_area">
                    <nav class="navbar  navbar-expand-lg align-items-center">
                        <!-- Logo -->
                        <a class="navbar-brand" href="index.html"><img src="../assets/img/petrollogo.png" width="350" height="350"
                                class="logo1" alt="logo"></a>

                        <!-- Menu Area -->
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mosh-navbar"
                            aria-controls="mosh-navbar" aria-expanded="false" aria-label="Toggle navigation"><span
                                class="navbar-toggler-icon"></span></button>

                        <div class="collapse navbar-collapse flex-column align-items-end " id="mosh-navbar">

                            <p style="float: right;">

                                <i class="fa fa-phone" aria-hidden="true" style="color: rgb(182, 182, 255);"></i>
                                <a href=" tel:99940-65552">&nbsp; 9994065552</a>
                                &nbsp;
                                &nbsp;

                                <i class="fa fa-envelope " aria-hidden="true" style="color: rgb(255, 160, 160);"></i>
                                <a href="mailto:admin@kgm.in">&nbsp;admin@kgm.in</a>

                            </p>


                            <ul class="navbar-nav animated navbottom" id="nav">
                                <li class="nav-item btn"><a class="nav-link" href="#top">Home</a></li>
                                <li class="nav-item btn"><a class="nav-link" href="#featureid">Features</a></li>
                                <li class="nav-item btn"><a class="nav-link" href="#products">Products</a></li>
                                <li class="nav-item btn"><a class="nav-link" href="#abountus">About Us</a></li>
                                <li class="nav-item btn"><a class="nav-link" href="#footer">Contact</a></li>
                            </ul>


                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>

</header>

<section>
    <div class="row" style="text-align: center;">
        <img src="../assets/img/petrolpump.jpg" style="margin:30px;height:auto;width: 90%; display: block;
    margin-left: auto;
    margin-right: auto;
    ">
    </div>
</section>


<section id="aboutusid" style="margin:20px;">
    <div class="container">
        <h4>Abount US</h4>
        <p>The mission to excel was the driving force behind the setting up of KGM Softwares Private Ltd. We provide
            versatile software packages that cater to different activities in various trades including Accounting,
            Inventory,
            Petrol Bunks, Textile Looms, Finances, Educational Institutions, Super Market & Departmental GST accounting
            software for Stores, Automobiles and Workshops and PetrolERP software for petrol pumps</p>

        <p>A widespread customer network over India for our Software for petrol pumps,Gst Accounting software.</p>
    </div>
    <br>
    <br>
</section>
<br>



<!-- <section>
    <div class="container" style="text-align: center;">

        <img src="../assets/img/pump2.png" style="height: 60px;">
        <br>
        <br>

        <p> this is to test
        </p>
    </div>
</section>
 -->


<section style="background-color:white;" id="featureid">

    <div class="container">


        <div class="row">
            <div class="col-md-12 toplab">
                <p style="text-align: center; font-size: larger;"> Get more features. Get more done.</p>

            </div>
        </div>
        <div class="row ">
            <div class="col-md-12">

                <div id="purchase">
                    <div class="row  text-center" id="p1">

                        <div class="col-md-4 box1">
                            <div class="box" style="background-color: #2883D0;">


                                <h5> Sales </h5><br>

                                <p><img src="../assets/img/pump.png" style="height: 100px;width: 90px;"> </p>
                                <br>
                                <p>Cash / Credit / Card bill. Card may Credit, Debit or Fleet Card. Regular Vehicle, New
                                    vehicle
                                    monitoring and So on..</p>
                            </div>
                        </div>

                        <div class="col-md-4 box1">
                            <div class="box" style="background-color: #45C6CC;">
                                <h5>Vehicle / Customer Monitoring</h5>
                                <br>
                                <p> <img src="../assets/img/truck.png" style="height: 75px;width: 175px;"> </p>
                                <br>
                                <p>Customer wise, Mobile Number wise vechile tracking, Vehicle History, Customer
                                    Abstract and so on..
                                </p>
                            </div>
                        </div>


                        <div class="col-md-4 box1">
                            <div class="box" style="background-color: #2883D0;">
                                <h5> Inventory</h5>
                                <br>
                                <p>
                                    <img src="../assets/img/tank.png" style="height: 75px;width: 160px;"> </p>
                                <br>
                                <p>Purchase,Stock adjustment, godownwise stock, Multi godown, Counter, item ledger and
                                    so on.. </p>
                            </div>
                        </div>

                    </div>


                    <!--Second row-->


                    <div class="row  text-center">
                        <div class="col-md-4 box1">
                            <div class="box" style="background-color: #45C6CC;">
                                <h5>Accounting</h5>
                                <br>
                                <p> <img src="../assets/img/account.png" style="height: 100px;width: 110px;"> </p>
                                <br>
                                <p>Daybook, Ledger,Trial balance,Balance sheet, Profit and loss account, outstanding,
                                    agewise report,
                                    VAT and so on..</p>
                            </div>
                        </div>

                        <div class="col-md-4 box1">
                            <div class="box" style="background-color: #2883D0;">
                                <h5>Updation</h5>
                                <br>
                                <p> <img src="../assets/img/update.png" style="height: 100px;width: 120px;"> </p>
                                <br>
                                <p>software which needs regular updates because we provide feature and enhancements.</p>
                            </div>
                        </div>


                        <div class="col-md-4 box1">
                            <div class="box" style="background-color: #45C6CC;">
                                <h5> Support </h5>
                                <br>
                                <p> <img src="../assets/img/support.png" style="height: 100px;width: 75px;"> </p>
                                <br>
                                <p>We provide experts who can diagnose and resolve issues and give advice on software
                                    features. Our
                                    Support gives businesses the right level of service to meet the demands</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  
    <br>
</section>

<section>
    <div class="bg">
        <div class="container">

            <div class="row py-5">
                <div class="col-md-12">
                    <div class="main-timeline">
                        <div class="timeline">
                            <a href="#" class="timeline-content">
                                <div class="timeline-icon">
                                    <i class="fa fa-paperclip"></i>
                                </div>
                                <h3 class="title">FLEXIBILITY</h3>
                                <p class="description">
                                    PetrolERP has provided much richer user interface. It is Pleasant, easy-to-navigate
                                    GUI, easy to
                                    learn and use.
                                </p>
                            </a>
                        </div>
                        <div class="timeline">
                            <a href="#" class="timeline-content">
                                <div class="timeline-icon">
                                    <i class="fa fa-mobile"></i>
                                </div>
                                <h3 class="title">ONLINE REPORTS</h3>
                                <p class="description">
                                    PetrolERP now has optimized for both the Android tablets and mobiles, you can access
                                    the
                                    reports on the go. It enables you to interact with your data and reports, helps you
                                    keep track of
                                    your key performance metrics and take informed business decisions, all, while
                                    on-the-move.
                                </p>
                            </a>
                        </div>
                        <div class="timeline">
                            <a href="#" class="timeline-content">
                                <div class="timeline-icon">
                                    <i class="fa fa-comments-o"></i>
                                </div>
                                <h3 class="title">COMMUNICATION</h3>
                                <p class="description">
                                    Communication helps us keep in touch with customers,Managers,owners at any time. We
                                    are able to send
                                    them SMS,eMail and Telegram. We can send transaction message, stock alert, greetings
                                    and so on.
                                </p>
                            </a>
                        </div>
                        <div class="timeline">
                            <a href="#" class="timeline-content">
                                <div class="timeline-icon">
                                    <i class="fa fa-lock"></i>
                                </div>
                                <h3 class="title">SECURITY</h3>
                                <p class="description">
                                    Multi user role based login.To protect your files you need to back them up. Create a
                                    backup schedule
                                    using the Backup server and keeping your backup off-site is the best way to ensure
                                    its safety.
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section style="background-color: #F5F4EA;">

    <div class="row">
        <div class="col-md-5 col-sm-12">
            <img src="../assets/img/pet.png" style="height: 350px;">
        </div><br>
        <div class="col-md-7 col-sm-12" style="font-size: 20px;"><br>
            <p style="text-align: left; font-size:x-large;color: black;">Salient Features</p>

            <ul id="a">
                <li>Billing - Cash, Credit, Card with multi price list </li>
                <li>Daily closing type - Manual, Daily once, Shift wise, Cashier wise</li>
                <li>History - Vehicle, Credit customer, Cash customer</li>
                <li>Discount - Vehicle wise, Party wise, Item wise, Quantity based</li>
                <li>Accounting - Daybook, Trail balance, ledger, Balance sheet, P&L</li>
                <li>Communication - SMS, Telegram, Email</li>
                <li>Backup - Auto Backup, Daily/Weekly/Monthly Backup on Google Drive or Telegram</li>
                <li>Invoice, Statement - Weekly/Monthly/Selected Period</li>
            </ul>


        </div>
    </div>

</section>






<footer class="footer-distributed" id="footer">

    <div class="footer-left">

        <img src="../assets/img/kgmpetrolbg.png" style="height: 60px;">
        <br><br>

        <p class="footer-company-name" style="color: white !important; font-size: larger;">KGM Softwares Private Ltd.,
        </p>

        <div>
            <i class="fa fa-map-marker"></i>
            <p><span>Mohan Cmplex, 235 Mettur Road,</span> Erode,Tamil Nadu 638011</p>
        </div>

        <div>
            <i class="fa fa-phone"></i>
            <p>+91 99940 65552</p>
        </div>

        <div>
            <i class="fa fa-envelope"></i>
            <p><a href="mailto:admin@kgm.in" style="color: white;">admin@kgm.in</a></p>
        </div>

        <div class="footer-icons">

            <a href="#"><i class="fa fa-facebook"></i></a>
            <a href="#"><i class="fa fa-youtube"></i></a>
            <a href="#"><i class="fa fa-whatsapp"></i></a>
            <a href="#"><i class="fa fa-twitter"></i></a>
            <a href="#"><i class="fa fa-telegram"></i></a>

        </div>
    </div>


    <div class="footer-center">
        <div class="contact-form-area">
            <h2>Get in touch</h2>
            <form action="#">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <input type="text" class="form-control" id="name" placeholder="Name">
                    </div>
                    <div class="col-12 col-md-6">
                        <input type="email" class="form-control" id="email" placeholder="Mobile">
                    </div>
                    <div class="col-12">
                        <input type="text" class="form-control" id="subject" placeholder="Subject">
                    </div>
                    <div class="col-12">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                            placeholder="Message"></textarea>
                    </div>
                    <button class="btn mt-50" style="height:50px" type="submit">Send Message</button>
                </div>
            </form>
        </div>


    </div>

    <div class="footer-right" style="margin-top: 50px; margin-left: 20px;">

        <div class="mapouter">
            <div class="gmap_canvas"><iframe width="376" height="300" id="gmap_canvas"
                    src="https://maps.google.com/maps?q=kgm%20softwwares&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
                    href="https://www.whatismyip-address.com/nordvpn-coupon/"></a></div>
            <style>
                .mapouter {
                    position: relative;
                    text-align: right;
                    height: 300px;
                    width: 376px;
                }

                .gmap_canvas {
                    overflow: hidden;
                    background: none !important;
                    height: 300px;
                    width: 376px;
                }
            </style>
        </div>

    </div>

</footer>